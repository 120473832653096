<template>
  <div class="container">
    <p class="title" v-cloak>{{ info.groupName }}群</p>
    <div class="img-div">
      <van-image
      @click="previewImage"
        width="172"
        height="172"
        :src="info.qrCode"
      ></van-image>
    </div>
    <div style="width: 100%; height: 10px"></div>

    <div class="step-one" v-if="isMini">
      <span >点击二维码长按识别添加群组 </span>
    </div>
    <div class="copy-btn" @click="toSaveImg" v-else>
      <van-image width="18" height="14"
              src="https://img1.clozhome.com/new-clozhome/mini/app_page_assets/community/wx.png"
      ></van-image>
      <div class="copy-wx" >点击保存图片</div>
    </div>

    <div class="step-two">
      <span>添加好友时, 请备注 "{{ remark }}" </span>
    </div>

    <div style="width: 100%; height: 20px"></div>
    <template v-if="isMini">
      <van-image width="250" height="159"
                 src="https://img1.clozhome.com/new-clozhome/community/touch.png"
      ></van-image>
    </template>
    <template v-else>
      <div class="step-item">
        <span class="step-txt">Step1</span>
        <span>点击上方按钮保存图片</span>
      </div>
      <div style="width: 100%; height: 11px"></div>
      <div class="step-item">
        <span class="step-txt">Step2</span>
        <span>点击微信右上角 "+" 点击 "扫一扫"功能 </span>
      </div>
      <div style="width: 100%; height: 10px"></div>
      <van-image
              width="250"
              height="125"
              src="https://img1.clozhome.com/new-clozhome/community/scan1.png"
      ></van-image>
      <div style="width: 100%; height: 17px"></div>
      <div class="step-item">
        <span class="step-txt">Step3</span>
        <span>相册中选择图片进行扫描并添加顾问,并备注您的姓名</span>
      </div>
      <div style="width: 100%; height: 11px"></div>
      <van-image
              width="251"
              height="107"
              src="https://img1.clozhome.com/new-clozhome/community/scan2.png"
      ></van-image>
    </template>


  </div>
</template>

<script>
import {wechatCommunityDetail } from "@/api/api";

// @ is an alias to /src
export default {
  name: "Activity",
  data() {
    return {
      loading: true,
      collegeName: "",
      type: "",
      copyData: "copyData",

      info:{}

    };
  },
  computed: {
    isMini() {
      return this.$store.state.mini;
    },
    remark() {
      let type = this.info.type
      if (type == 1) {
        return '校友群'
      } else if (type == 2) {
        return '租房群'
      }
      return  '租房'
    }
  },
  created() {
    wechatCommunityDetail({id:this.$route.query.id}).then(res=>{
      //获取社群详细信息
      this.info = res.data
    })
  },
  methods: {
    toSaveImg(){
      savePictureToLocalAlbum.postMessage(JSON.stringify({
        posterUrl: this.info.qrCode
      }))
    },
    previewImage(){
      console.log('放大预览')
      wx.previewImage({
        current: this.info.qrCode, // 当前显示图片的 http 链接
        urls: [this.info.qrCode] // 需要预览的图片 http 链接列表
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  padding: 18px 63px;
}
.title {
  @include center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #f67f58;
  margin-bottom: 18px;
}
.img-div {
  width: 250px;
  height: 240px;
  background: #e5e5e5;
  @include center;
}
.copy-btn {
  @include center;
  width: 250px;
  height: 35px;
  background: #20aa1c;
  box-shadow: 0px 1px 4px 0px rgba(69, 69, 69, 0.3);
  border-radius: 5px;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  .copy-wx {
    margin-left: 11px;
  }
}
.type-tip {
  @include center;
  font-size: 11px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #f67f58;
}
.step-one{
  text-align: center;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.step-two{
  margin-top: 22px;
  text-align: center;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #666666;
}
.step-item {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  .step-txt {
    color: #f67f58;
    margin-right: 8px;
  }
}
</style>
